import { defineStore } from "pinia";

export const useFilteringStore = defineStore("filtering", {
  state: () => ({
    userClaimState: [] as string[],
    categories: [] as string[]
  }),
  // actions: {
  //   setUserClaimState(values: string[]) {
  //     this.userClaimState = [...values];
  //   },
  //   // Add a source | origin | submitterNotes ID to a claim
  //   setCategories(categories: string[]) {
  //     this.categories = [...categories];
  //   }
  // },
  getters: {
    // getCategories: (state) => () => state.categories,
    // getUserClaimState: (state) => () => state.userClaimState,
    getIsMarked: (state) => () => state.userClaimState.includes("isMarked"),
    getIsNew: (state) => () => state.userClaimState.includes("isNew"),
    getIsCommunity: (state) => () => state.userClaimState.includes("isCommunity")
  }
  // persist: {
  //   storage: persistedState.sessionStorage
  // }
});
